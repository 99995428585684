import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SetupPageNewContext } from './context'

export const SetupPageNewView = (): JSX.Element => {
  const loading = useContextSelector(SetupPageNewContext, (s) => s.loading)

  const handleStartSetup = useContextSelector(
    SetupPageNewContext,
    (s) => s.handleStartSetup
  )

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <Typography variant="h1" color="secondary.main">
          Onboarding
        </Typography>
        <Typography variant="subtitle1" color="muted.main" lineHeight={1.4}>
          Sua organização ainda não iniciou as configurações necessárias para se
          conectar com suas ferramentas de gestão.
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Typography variant="subtitle2" color="muted.main" lineHeight={1.4}>
          Clique no botão abaixo para iniciar o processo de configuração.
        </Typography>
        <LoadingButton
          variant="contained"
          size="large"
          loading={loading}
          onClick={handleStartSetup}
        >
          {loading ? 'Iniciando integração...' : 'Iniciar integração'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
