import { Container, Wrapper } from './styles'
import { Gauge } from '@phosphor-icons/react'
import { PanelMenuItem } from './components/item'

export const PanelMenu = (): JSX.Element | null => {
  return (
    <Wrapper>
      <Container>
        <PanelMenuItem icon={Gauge} label="Dashboard" href={`/organization`} />
      </Container>
    </Wrapper>
  )
}
