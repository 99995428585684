import { SetupPageDoneController } from './controller'
import { SetupPageDoneProps } from './types'
import { SetupPageDoneView } from './view'

export const SetupPageDone = (props: SetupPageDoneProps): JSX.Element => {
  return (
    <SetupPageDoneController {...props}>
      <SetupPageDoneView />
    </SetupPageDoneController>
  )
}
