import { SetupPageStepsEnum } from '../types'
import { SetupPageScrumForm } from './setup-scrum'
import { useSearchParams } from 'react-router-dom'
import { SetupPageRepositoryForm } from './setup-repository'
import { SetupPageNew } from './setup-new'
import { SetupPageDone } from './setup-done'

export const SetupPageSelector = (): JSX.Element | null => {
  const [searchParams] = useSearchParams()
  const step = searchParams.get('step') || SetupPageStepsEnum.new

  if (step === SetupPageStepsEnum.new) return <SetupPageNew />
  if (step === SetupPageStepsEnum.scrum) return <SetupPageScrumForm />
  if (step === SetupPageStepsEnum.repository) return <SetupPageRepositoryForm />
  if (step === SetupPageStepsEnum.done) return <SetupPageDone />

  return null
}
