import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

export const OrganizationPageLoading = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={2}>
        <Grid item sm={3} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" spacing={8}>
            <Stack direction="column" spacing={1} alignItems="start">
              <Box width="100%">
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={3} alignItems="center">
                    <Typography variant="h1" color="secondary.main">
                      Carregando...
                    </Typography>
                    <CircularProgress size={32} />
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    color="muted.main"
                    lineHeight={1.4}
                  >
                    Estamos buscando os dados da sua organização, além disso
                    estamos verificando se todas as configurações foram
                    realizadas corretamente.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
