/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useApi } from '@hooks/api'
import { useAuth } from '@hooks/auth'
import { OrganizationPageContext } from './context'
import { OrganizationPageControllerProps } from './types'
import { UserContextEnum } from '@enums/user_context_enum'
import { OrganizationApi } from '@services/api/organization_api'
import { OrganizationSettingsEntity } from '@entities/OrganizationSettingsEntity'
import { useSnackbar } from 'notistack'

export const OrganizationPageController = (
  props: OrganizationPageControllerProps
): JSX.Element => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [organizationSettings, setOrganizationSettings] = useState<
    OrganizationSettingsEntity | undefined
  >(undefined)

  useEffect(() => {
    if (user?.context === UserContextEnum.ORGANIZATION) {
      setLoading(true)
      api
        .instanceOf<OrganizationApi>(OrganizationApi)
        .findSettings()
        .then(setOrganizationSettings)
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message || error.message)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const state = useMemo(
    () => ({ loading, organizationSettings, setOrganizationSettings }),
    [loading, organizationSettings, setOrganizationSettings]
  )

  return (
    <OrganizationPageContext.Provider value={state}>
      {props.children}
    </OrganizationPageContext.Provider>
  )
}
