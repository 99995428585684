import { OrganizationPageView } from './view'
import { OrganizationPageProps } from './types'
import { OrganizationPageController } from './controller'

export const OrganizationPage = (props: OrganizationPageProps): JSX.Element => {
  return (
    <OrganizationPageController {...props}>
      <OrganizationPageView />
    </OrganizationPageController>
  )
}
