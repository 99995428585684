/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { useSnackbar } from 'notistack'
import { SetupPageNewContext } from './context'
import { SetupPageNewControllerProps } from './types'
import { useCallback, useMemo, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { OrganizationApi } from '@services/api/organization_api'
import { OrganizationPageContext } from '@pages/organization/context'
import { SetupPageContext } from '@pages/organization_setup/context'
import { SetupPageStepsEnum } from '@pages/organization_setup/types'

export const SetupPageNewController = ({
  children,
}: SetupPageNewControllerProps): JSX.Element => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const setStep = useContextSelector(SetupPageContext, (s) => s.setStep)

  const organizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.organizationSettings
  )

  const setOrganizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.setOrganizationSettings
  )

  const handleStartSetup = useCallback(() => {
    if (!!organizationSettings) {
      setStep(SetupPageStepsEnum.scrum)
    } else {
      setLoading(true)
      api
        .instanceOf<OrganizationApi>(OrganizationApi)
        .createSettings()
        .then(setOrganizationSettings)
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message || error?.message)
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const state = useMemo(
    () => ({
      loading,
      handleStartSetup,
    }),
    [loading, handleStartSetup]
  )

  return (
    <SetupPageNewContext.Provider value={state}>
      {children}
    </SetupPageNewContext.Provider>
  )
}
