import JiraIcon from '@assets/icons/jira.png'

export enum ScrumProviderEnum {
  jira = 'jira',
}

export enum ScrumLabelEnum {
  jira = 'Jira',
}

export const ScrumIconEnum = {
  jira: JiraIcon,
}

export type ScrumProviderEnumKeys = keyof typeof ScrumProviderEnum
