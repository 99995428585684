/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@hooks/api'
import { useAuth } from '@hooks/auth'
import { useSnackbar } from 'notistack'
import { useCookies } from '@hooks/cookies'
import { SessionPageContext } from './context'
import { AuthApi } from '@services/api/auth_api'
import { UserEntity } from '@entities/UserEntity'
import { SessionPageControllerProps } from './types'
import { UserContextEnum } from '@enums/user_context_enum'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export const SessionPageController = (
  props: SessionPageControllerProps
): JSX.Element => {
  const api = useApi()
  const auth = useAuth()
  const cookies = useCookies()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(true)

  const handleRedirectUser = (user: UserEntity) => {
    // update user context
    auth.setUser(user)

    // skip redicteion if pathname is
    // related the oauth callback url
    if (pathname.startsWith('/oauth_callback')) return

    // redirect user to organization screen if
    // user belongs to organization context
    if (user.context === UserContextEnum.ORGANIZATION) {
      const path = `/organization`
      if (!pathname.startsWith(path)) navigate(`${path}`, { replace: true })
    }
    // redirect user to professional screen if
    // user belongs to professional context
    else if (user.context === UserContextEnum.PROFESSIONAL) {
      const path = `/professional`
      if (!pathname.startsWith(path)) navigate(`${path}`, { replace: true })
    }
    // redirect user to account page if user does not belongs
    // to any context, there it will choose one conxted
    else {
      window.location.href = `${process.env.REACT_APP_ACCOUNT_URL!}?callback=${
        window.location.href
      }`
    }
  }

  const handleLoadSession = useCallback(() => {
    // start loading indicator
    setLoading(true)

    // update cookie authorization if it was present
    // on search params (workaround for dev environment)
    const authorization = searchParams.get('Authorization')
    if (!!authorization) {
      searchParams.delete('Authorization')
      cookies.setCookies({ Authorization: authorization })
    }

    // call session api to retrieve user data
    // and start application logic handling
    api
      .instanceOf<AuthApi>(AuthApi)
      .session()
      .then(handleRedirectUser)
      .catch(() => enqueueSnackbar('Falha ao obter sessão do usuário'))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    handleLoadSession()
  }, [])

  const state = useMemo(() => ({ loading }), [loading])

  return (
    <SessionPageContext.Provider value={state}>
      {props.children}
    </SessionPageContext.Provider>
  )
}
