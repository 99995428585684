import { SessionPage } from './pages/session'
import { SetupPage } from './pages/organization_setup'
import { OrganizationPage } from '@pages/organization'
import { OAuthCallbackPage } from '@pages/oauth_callback'
import { Routes, Route, Navigate } from 'react-router-dom'

function Router() {
  return (
    <Routes>
      <Route element={<SessionPage />}>
        <Route
          path="oauth_callback/:resource/:provider"
          element={<OAuthCallbackPage />}
        />
        <Route path="setup">
          <Route path="" element={<SetupPage />} />
          <Route path="*" element={<Navigate to="/setup" replace />} />
        </Route>
        <Route path="organization" element={<OrganizationPage />}>
          <Route path="" element={<div>overview</div>} />
          <Route path="*" element={<Navigate to="/organization" replace />} />
        </Route>
        <Route path="professional" element={<div>PROFESSIONAL</div>} />
        <Route path="*" element={<div>404</div>} />
      </Route>
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}

export default Router
