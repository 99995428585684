import { Stack, Step, StepLabel, Stepper } from '@mui/material'
import { SetupPageStepsEnum } from '../types'
import { useSearchParams } from 'react-router-dom'

export const SetupPageStepper = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const step = searchParams.get('step') || SetupPageStepsEnum.new

  const steps = [
    { key: SetupPageStepsEnum.new, label: 'Setup' },
    { key: SetupPageStepsEnum.scrum, label: 'Scrum' },
    { key: SetupPageStepsEnum.repository, label: 'Repositório' },
    { key: SetupPageStepsEnum.done, label: 'Conclusão' },
  ]

  const stepIndex = steps.findIndex((i) => i.key === step)

  return (
    <Stack direction="column">
      <Stepper activeStep={stepIndex} alternativeLabel>
        {steps.map((i) => (
          <Step key={`step-${i.key}`}>
            <StepLabel>{i.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  )
}
