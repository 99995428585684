/* eslint-disable react-hooks/exhaustive-deps */
import { SetupPageContext } from './context'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { SetupPageControllerProps, SetupPageStepsEnum } from './types'
import { OrganizationPageContext } from '@pages/organization/context'

export const SetupPageController = (
  props: SetupPageControllerProps
): JSX.Element => {
  const [searchParam, setSearchParams] = useSearchParams()
  const organizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.organizationSettings
  )

  const setStep = (path: SetupPageStepsEnum) => {
    setSearchParams({ step: path })
  }

  useEffect(() => {
    if (!organizationSettings) {
      setStep(SetupPageStepsEnum.new)
    } else if (!searchParam.get('step')) {
      if (!organizationSettings.scrum) {
        setStep(SetupPageStepsEnum.scrum)
      } else if (!organizationSettings.repository) {
        setStep(SetupPageStepsEnum.repository)
      } else {
        setStep(SetupPageStepsEnum.done)
      }
    }
  }, [organizationSettings])

  const state = useMemo(() => ({ setStep }), [setStep])

  return (
    <SetupPageContext.Provider value={state}>
      {props.children}
    </SetupPageContext.Provider>
  )
}
