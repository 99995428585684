import { Panel } from '@containers/panel'
import { SetupPage } from '@pages/organization_setup'
import { OrganizationPageContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { OrganizationPageLoading } from './containers/loading'
import { OrganizationStatusEnum } from '@enums/organization_status_enum'

export const OrganizationPageView = (): JSX.Element => {
  const loading = useContextSelector(OrganizationPageContext, (s) => s.loading)
  const organizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.organizationSettings
  )

  if (loading) return <OrganizationPageLoading />

  if (organizationSettings?.status !== OrganizationStatusEnum.ACTIVE) {
    return <SetupPage />
  }

  return <Panel />
}
