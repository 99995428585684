import { SetupPageNewController } from './controller'
import { SetupPageNewProps } from './types'
import { SetupPageNewView } from './view'

export const SetupPageNew = (props: SetupPageNewProps): JSX.Element => {
  return (
    <SetupPageNewController {...props}>
      <SetupPageNewView />
    </SetupPageNewController>
  )
}
