export type SetupPageForm = {
  name?: string
}

export type SetupPageContextType = {
  setStep: (path: SetupPageStepsEnum) => void
}

export type SetupPageControllerProps = {
  children: JSX.Element
}

export enum SetupPageStepsEnum {
  'new' = 'new',
  'scrum' = 'scrum',
  'repository' = 'repository',
  'done' = 'done',
}
