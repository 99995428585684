import { ApiAction } from '../../hooks/api/action'
import { ScrumAccountSetupFormType } from '@type/scrum_account_setup_form_type'
import { RepositoryAccountSetupFormType } from '@type/repository_account_setup_form_type'
import { OrganizationSettingsEntity } from '@entities/OrganizationSettingsEntity'

export class OrganizationApi extends ApiAction {
  async findSettings(): Promise<OrganizationSettingsEntity> {
    const response = await this.http.get<OrganizationSettingsEntity>(
      `/organization/settings`
    )
    return response.data
  }

  async createSettings(): Promise<OrganizationSettingsEntity> {
    const response = await this.http.post<OrganizationSettingsEntity>(
      `/organization/settings`
    )
    return response.data
  }

  async update(
    data: Partial<OrganizationSettingsEntity>
  ): Promise<OrganizationSettingsEntity> {
    const response = await this.http.put<OrganizationSettingsEntity>(
      `/organization`,
      data
    )
    return response.data
  }

  async removeScrumAccount(): Promise<OrganizationSettingsEntity> {
    const response = await this.http.delete<OrganizationSettingsEntity>(
      `/organization/scrum`
    )
    return response.data
  }

  async setupScrumAccount(
    account: Partial<ScrumAccountSetupFormType>
  ): Promise<OrganizationSettingsEntity> {
    const response = await this.http.post<OrganizationSettingsEntity>(
      `/organization/setup/scrum`,
      account
    )
    return response.data
  }

  async removeRepositoryAccount(): Promise<OrganizationSettingsEntity> {
    const response = await this.http.delete<OrganizationSettingsEntity>(
      `/organization/repository`
    )
    return response.data
  }

  async setupRepositoryAccount(
    account: Partial<RepositoryAccountSetupFormType>
  ): Promise<OrganizationSettingsEntity> {
    const response = await this.http.post<OrganizationSettingsEntity>(
      `/organization/setup/repository`,
      account
    )
    return response.data
  }

  async setupFinish(): Promise<OrganizationSettingsEntity> {
    const response = await this.http.get<OrganizationSettingsEntity>(
      `/organization/setup/done`
    )
    return response.data
  }
}
