/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react'
import { useApi } from '@hooks/api'
import { useSnackbar } from 'notistack'
import { SetupPageDoneContext } from './context'
import { SetupPageDoneControllerProps } from './types'
import { useContextSelector } from 'use-context-selector'
import { OrganizationApi } from '@services/api/organization_api'
import { OrganizationPageContext } from '@pages/organization/context'

export const SetupPageDoneController = ({
  children,
}: SetupPageDoneControllerProps): JSX.Element => {
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const setOrganizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.setOrganizationSettings
  )

  const handleFinishIntegration = useCallback(() => {
    setLoading(true)
    api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .setupFinish()
      .then(setOrganizationSettings)
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message || error?.message)
      })
      .finally(() => setLoading(false))
  }, [])

  const state = useMemo(
    () => ({
      loading,
      handleFinishIntegration,
    }),
    [loading, handleFinishIntegration]
  )

  return (
    <SetupPageDoneContext.Provider value={state}>
      {children}
    </SetupPageDoneContext.Provider>
  )
}
