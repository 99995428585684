import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SetupPageDoneContext } from './context'
import { OrganizationPageContext } from '@pages/organization/context'
import { ScrumIconEnum, ScrumLabelEnum } from '@enums/scrum_provider_enum'
import {
  RepositoryIconEnum,
  RepositoryLabelEnum,
} from '@enums/repository_provider_enum'
import { ArrowLeft } from '@phosphor-icons/react'
import { SetupPageContext } from '@pages/organization_setup/context'
import { SetupPageStepsEnum } from '@pages/organization_setup/types'

export const SetupPageDoneView = (): JSX.Element => {
  const loading = useContextSelector(SetupPageDoneContext, (s) => s.loading)

  const organizationSettings = useContextSelector(
    OrganizationPageContext,
    (s) => s.organizationSettings
  )

  const handleFinishIntegration = useContextSelector(
    SetupPageDoneContext,
    (s) => s.handleFinishIntegration
  )

  const setStep = useContextSelector(SetupPageContext, (s) => s.setStep)

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Button
          variant="text"
          size="medium"
          sx={{ px: 2 }}
          color="secondary"
          startIcon={<ArrowLeft />}
          onClick={() => setStep(SetupPageStepsEnum.repository)}
        >
          Voltar
        </Button>
        <Typography variant="h1" color="secondary.main">
          Conclusão
        </Typography>
        <Typography variant="subtitle1" color="muted.main" lineHeight={1.4}>
          As ferramentas necessários foram configuradas com sucesso, agora você
          pode revisar as suas escolhas e finalizar sua integração.
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            src={_.get(
              ScrumIconEnum,
              organizationSettings?.scrum?.provider || ''
            )}
            alt={organizationSettings?.scrum?.provider}
            width={16}
            height={16}
          />
          <Typography>
            {_.get(ScrumLabelEnum, organizationSettings?.scrum?.provider || '')}
            {` / `}
            {organizationSettings?.scrum?.name}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            src={_.get(
              RepositoryIconEnum,
              organizationSettings?.repository?.provider || ''
            )}
            alt={organizationSettings?.repository?.provider}
            width={16}
            height={16}
          />
          <Typography>
            {_.get(
              RepositoryLabelEnum,
              organizationSettings?.repository?.provider || ''
            )}
            {` / `}
            {organizationSettings?.repository?.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <LoadingButton
          variant="contained"
          size="large"
          loading={loading}
          onClick={handleFinishIntegration}
        >
          {loading ? 'Finalizando...' : 'Concluir Integração'}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
