import GithubIcon from '@assets/icons/github.png'
import GitlabIcon from '@assets/icons/gitlab.png'
import BitbucketIcon from '@assets/icons/bitbucket.png'

export enum RepositoryProviderEnum {
  bitbucket = 'bitbucket',
  gitlab = 'gitlab',
  github = 'github',
}

export enum RepositoryLabelEnum {
  bitbucket = 'Bitbucket',
  gitlab = 'GitLab',
  github = 'Github',
}

export const RepositoryIconEnum = {
  bitbucket: BitbucketIcon,
  gitlab: GitlabIcon,
  github: GithubIcon,
}

export type RepositoryProviderEnumKeys = keyof typeof RepositoryProviderEnum
